import React, { Component } from 'react';
import ContactUs from './ContactUs';
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
} from "@vis.gl/react-google-maps";
import { withTranslation } from 'react-i18next';

class Formulario extends Component {
  componentDidMount() {
    document.title = "Contacto - Veterinaria Los Cuartos"
  }
  render() {
    const position = { lat: 28.391760, lng: -16.517671 };
    const { t } = this.props
    return (
      <React.Fragment>
        <div className="headerSpace" />
        <h1 className="subheader">{t("formulario.tituloForm")}</h1>
        <div className="center">
          <div id="content">
            <ContactUs />
            <div className="map">
              <APIProvider apiKey={"AIzaSyBkv5yFJqzUAjMVDl6SS8xF-5uKnu9vzRE"}>
                <div id="myMap">
                  <Map zoom={16} center={position} mapId={"3b5d8655fc778110"}>
                    <AdvancedMarker position={position}>
                      <Pin
                        background={"rgb(255,214,146)"}
                        borderColor={"rgb(200,200,200)"}
                        glyphColor={"rgb(0,156,160)"}
                      />
                    </AdvancedMarker>
                  </Map>
                </div>
              </APIProvider>
              <div className="map-location">
                <h1>{t("map.localizacion")}</h1>
                <div className="mapContent">
                  <div className="mapSection">
                    <h2>{t("map.direccion")}</h2>
                    <p>Av. Sor Soledad Cobian, 29, 38300 La Orotava, Santa Cruz de Tenerife</p>
                  </div>
                  <div className="mapSection">
                    <h2>{t("map.horario")}</h2>
                    <h3>{t("map.dias")}</h3>
                    <p>9:30 a 20:00 H</p>
                    <h3>{t("map.dias2")}</h3>
                    <p>9:30 a 13:30 H</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation("global")(Formulario);
