import React, { useState, Suspense } from 'react';
import { useTranslation } from "react-i18next"
const CookieConsent = React.lazy(() => import("react-cookie-consent"));

function Cookies() {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const [t] = useTranslation("global");

    return (
        <React.Fragment>
            <Suspense fallback={<div>Loading...</div>}>
                {click ?
                    <CookieConsent
                        debug={true}
                        location="bottom"
                        style={{ background: "darkcyan" }}
                        buttonStyle={{ color: "#000", background: "withesmoke", fontSize: "14px" }}
                        buttonText={t("cookies.title")}
                        sameSite='none'
                    >
                        {t("cookies.descripcion")}
                    </CookieConsent>
                    :
                    <div className="cookie" onClick={handleClick}>
                        {t("cookies.show")}
                    </div>
                }
            </Suspense>
        </React.Fragment>
    );
}

export default Cookies;