import React, { Component } from 'react';
import '../assets/css/Blog.css';

class Sidebar extends Component {
    render() {
        return (
            <div className="sidebar">
            <div className="section topics">
                <h1>Temas</h1>
                <ul>
                    <li>Salud</li>
                </ul>
            </div>
            </div>
        );
    }
}

export default Sidebar;