import { initReactI18next } from "react-i18next"
import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector";

import global_es from "./translations/es/global.json"
import global_en from "./translations/en/global.json"
import global_de from "./translations/de/global.json"

const userLang = navigator.language || navigator.userLanguage;
const defaultLocale = userLang.substring(0, 2);

const languages = ["es", "en", "de"];

const options = {
  order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], 
  cookieMinutes: 10,
  cookieDomain: 'clinicaveterinarialoscuartos.net',
  htmlTag: document.documentElement,
  cookieOptions: { path: '/', sameSite: 'strict' }
}
const resources = {
    en:{
        global: global_en
      },
    es:{
        global: global_es
      },
    de:{
        global: global_de
    }
}
i18next
.use(initReactI18next)
.use(LanguageDetector)
.init({
  detection: options,
  load: 'languageOnly',
  lng: defaultLocale,
  fallbackLng: "es",
  interpolation: { escapeValue: false },
  whitelist: languages,
  resources: resources
})