import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import logo from '../assets/images/ligo.webp';
import { NavLink } from 'react-router-dom';
import '../assets/css/Header.css';
import { useTranslation } from "react-i18next"

function Header() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const [navbar, setNavbar] = useState(false);
  const [t] = useTranslation("global");

  const changeBackground = () => {
    if (window.scrollY >= 40) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', changeBackground, {passive: true});
    return () => {
      window.removeEventListener('scroll', changeBackground);
    };
  }, []);

  return (
    <div itemScope itemType="http://schema.org/Product">
      <div className="upper">
        <div className="flex-start">
          <div>
            <i className="fa fa-phone p-1" />
            <span>922322294</span>
          </div>
          <div>
            <i className="fa fa-envelope p-1" />
            <span>cvetloscuartos@gmail.com</span>
          </div>
        </div>
        <div className="flex-end">
          <a className="facebook" href="https://www.facebook.com/ClinicaVeterinariaLosCuartos/" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
        </div>
      </div>
      <nav className={navbar ? 'navbar active' : 'navbar'}>
        <div className="navbar-logo">
          <NavLink to='/home' onClick={closeMobileMenu}>
            <img src={logo} className="navbar-logo" alt="logotipo" />
          </NavLink>
        </div>
        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='nav-item'>
            <NavLink to='/home' activeclassname="activelink" className='nav-links' onClick={closeMobileMenu}>
              {t("navbar.inicio")}
            </NavLink>
          </li>
          {/*<li className='nav-item'>
            <NavLink to='/blog' activeclassname="activelink" className='nav-links' onClick={closeMobileMenu}>
              Blog
            </NavLink>
          </li>*/}
          <li className='nav-item'>
            <NavLink to='/servicios' activeclassname="activelink" className='nav-links' onClick={closeMobileMenu}>
              {t("navbar.servicios")}
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink to='/equipo' activeclassname="activelink" className='nav-links' onClick={closeMobileMenu}>
              {t("navbar.nuestroEquipo")}
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink to='/formulario' activeclassname="activelink" className='nav-links' onClick={closeMobileMenu}>
              {t("navbar.contactanos")}
            </NavLink>
          </li>
          <li className='nav-item'>
            <a itemProp="url" className="wha" onClick={closeMobileMenu} href="https://wa.me/34650833022" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
          </li>
          <li className='upperCollapse'>
            <div>
              <i className="fa fa-phone p-1" />
              <span>922322294</span>
            </div>
          </li>
          <li className='upperCollapse'>
            <div>
              <i className="fa fa-envelope p-1" />
              <span>cvetloscuartos@gmail.com</span>
            </div>
          </li>
          <li className='upperCollapse'>
            <a onClick={closeMobileMenu} className="facebook" href="https://www.facebook.com/ClinicaVeterinariaLosCuartos/" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Header;