import React from 'react';
import logo from '../assets/images/ligo.webp';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next"

const Footer = () => {
	const [t] = useTranslation("global");
	return (
		<footer id="footer">
			<div className="container">
				<aside>
					<h1>{t("footer.direccionContacto")}</h1>
					<ul className="unstyled">
						<div>
							<li><i className="fa fa-envelope p-1"></i> cvetloscuartos@gmail.com</li>
						</div>
						<div>
							<li><i className="fa fa-phone p-1"></i> +34  922 32 22 94 </li>
						</div>
						<li><i className="fa fa-home p-1"></i> Av. Sor Soledad Cobian, 27 -29,  38300 La Orotava, Santa Cruz de Tenerife</li>
						<a className="social" href="https://www.facebook.com/ClinicaVeterinariaLosCuartos/" target="_blank" rel="noreferrer">
							<li><i className="fa fa-facebook-official facebook p-1"></i></li>
						</a>
					</ul>
				</aside>
				<aside>
					<h1>{t("footer.nosotros")}</h1>
					<ul className="unstyled">
						<li>{t("footer.nosotrosDesc")}</li>
						<li><img src={logo} className="navbar-logo" alt="logotipo" /></li>
					</ul>
				</aside>
			</div>
			<div className="bottomFooter">
				<p>
					&copy; 2021 Clínica Veterinaria los Cuartos -  
					<Link to='/aviso-legal'
					className="text-d-none">
                        {t("footer.avisoLegal")}
                    </Link>
				</p>
			</div>
		</footer>
	);
}

export default Footer;