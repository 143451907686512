import React, { Component } from 'react';
import Trabajador from './Trabajador';

import { withTranslation } from 'react-i18next';

import i1 from '../assets/images/sandra.webp';
import i2 from '../assets/images/Carlos.webp';
import i4 from '../assets/images/Eugenio.webp';
import i5 from '../assets/images/Ana.webp';
import i6 from '../assets/images/Sandra2.webp';
import i7 from '../assets/images/LauraHdez.webp';
import i8 from '../assets/images/Dulce.webp';
import i9 from '../assets/images/Soraya.webp';
import i10 from '../assets/images/Gregorio.webp';
import i11 from '../assets/images/Maria.webp';
import i12 from '../assets/images/Vanesa.webp';

class Trabajadores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trabajadores: [
                { nombre: 'Sandra Jiménez García', image: i1, descripcion: props.t("equipo.sandra") },
                { nombre: 'Juan Carlos Hernández Vargas', image: i2, descripcion: props.t("equipo.juanCarlos") },
                { nombre: 'Eugenio Hernández González', image: i4, descripcion: props.t("equipo.eugenio") },
                { nombre: 'Ana Hernández Pérez', image: i5, descripcion: props.t("equipo.ana") },
                { nombre: 'Sandra Hernández Cazallas', image: i6, descripcion: props.t("equipo.sandra2") },
                { nombre: 'Laura Hernández Jiménez', image: i7, descripcion: props.t("equipo.lauraHdez") },
                { nombre: 'Dulce María Hernández Hernández', image: i8, descripcion: props.t("equipo.dulce") },
                { nombre: 'Soraya Lobato González', image: i9, descripcion: props.t("equipo.soraya") },
                { nombre: 'Gregorio Alexis Luis Luis', image: i10, descripcion: props.t("equipo.gregorio") },
                { nombre: 'María Jesús Canet de Armas', image: i11, descripcion: props.t("equipo.maria") },
                { nombre: 'Vanesa García Aguiar', image: i12, descripcion: props.t("equipo.vanesa") }
            ]
        };
    }

    componentDidMount() {
        document.title = "Equipo - Veterinaria Los Cuartos";
    }

    render() {
        return (
            <React.Fragment>
                <div className="headerSpace" />
                <div id="articles">
                    <article className="container">
                        {this.state.trabajadores.map((trabajador, i) => (
                            <Trabajador
                                key={i}
                                trabajador={trabajador}
                                indice={i}
                            />
                        ))}
                    </article>
                </div>
            </React.Fragment>
        );
    }
}

export default withTranslation("global")(Trabajadores);
