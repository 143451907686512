import React, { Component } from 'react';
import ServicesCard from './ServicesCard';

import { withTranslation } from 'react-i18next';

import i1 from '../assets/images/Clinica3.webp';
import i2 from '../assets/images/Acupunture.webp';
import i3 from '../assets/images/Exotics.webp';
import i4 from '../assets/images/LaserSurgery.webp';
import i5 from '../assets/images/laser2.webp';
import i6 from '../assets/images/Traumatology.webp';


class Cards extends Component {
    constructor(props) {
        super(props);
        // Inicializa el estado aquí
        this.state = {
            servicios: [{
                nombre: this.props.t("especialidades.acupuntura"), image: i2,
                descripcion: this.props.t("especialidades.acupunturaShort"),
                longDesc: this.props.t("especialidades.acupunturaLong"),
                listDesc: this.props.t("especialidades.acupunturaList", { returnObjects: true })
            },
            {
                nombre: this.props.t("especialidades.exoticos"), image: i3,
                descripcion: this.props.t("especialidades.exoticosShort"),
                longDesc: this.props.t("especialidades.exoticosLong"),
                listDesc: this.props.t("especialidades.exoticosList", { returnObjects: true })
            },
            {
                nombre: this.props.t("especialidades.trauma"), image: i6,
                descripcion: this.props.t("especialidades.traumaShort"),
                longDesc: this.props.t("especialidades.traumaLong"),
                listDesc: this.props.t("especialidades.traumaList", { returnObjects: true })
            },
            {
                nombre: this.props.t("especialidades.laserquirurgico"), image: i4,
                descripcion: this.props.t("especialidades.laserquirurgicoShort"),
                longDesc: this.props.t("especialidades.laserquirurgicoLong")
            },
            {
                nombre: this.props.t("especialidades.laserfisio"), image: i5,
                descripcion: this.props.t("especialidades.laserfisioShort"),
                longDesc: this.props.t("especialidades.laserfisioLong"),
                listDesc: this.props.t("especialidades.laserfisioList", { returnObjects: true })
            },
            {
                nombre: this.props.t("especialidades.peluqueria"), image: i1,
                descripcion: this.props.t("especialidades.peluqueriaShort"),
                longDesc: this.props.t("especialidades.peluqueriaLong")
            }]
        };
    }

    render() {
        return (
            <div className="container">
                {
                    this.state.servicios.map((servicio, i) => (
                        <ServicesCard
                            key={i}
                            servicio={servicio}
                            indice={i}
                        />
                    ))
                }
            </div>
        );
    }
}

export default withTranslation("global")(Cards);
