import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';

class ContactUs extends Component {
  state = {
    name: '',
    email: '',
    phone: '',
    message: '',
    emailStatus: ''
};

// handle the value
handleCHange = input => e => {
    this.setState({[input]: e.target.value});
}

// when submit btn is clicked
submitForm = (e) => {
    const {name, email, phone, message} = this.state;


    // create a new XMLHttpRequest
    var xhr = new XMLHttpRequest();

    // get a callback when the server responds
    xhr.addEventListener('load', () => {
        // update the response state and the step
        
        this.setState ({
            emailStatus: xhr.responseText
        });
    });
    // open the request with the verb and the url
    xhr.open('GET', 'https://email.clinicaveterinarialoscuartos.net/index.php?sendto=' + email + 
                            '&name=' + name + '&phone=' + phone +
                            '&message=' + message);
    // send the request
    xhr.send();

    // reset the fields
    this.setState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    e.preventDefault();
}

render(){
    const {name, email, phone, message, emailStatus} = this.state;
    const {t} = this.props
    return(
        <div className="formBlock" onSubmit={this.submitForm}>
            {emailStatus ? emailStatus : null}
            <form>
                <label>
                    <input className="text-input" type="text" value={name} onChange={this.handleCHange('name')} placeholder={t("formulario.nombre")} />
                </label>
                <label>
                    <input className="text-input" type="text" value={email} onChange={this.handleCHange('email')} placeholder={t("formulario.email")} />
                </label>
                <label>
                    <input className="text-input" type="text" value={phone} onChange={this.handleCHange('phone')} placeholder={t("formulario.telefono")} />
                </label>
                <label>
                    <textarea className="text-input" type="text" value={message} onChange={this.handleCHange('message')} placeholder={t("formulario.mensaje")}></textarea>
                </label>
                <label>
                    <input className="learn-more" type="submit" value={t("formulario.btnForm")} />
                </label>
            </form>
        </div>
    );
}
}

export default withTranslation("global")(ContactUs);