import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../assets/css/slider.scss';
import logo from '../assets/images/ligo.webp';
import i1 from '../assets/images/Clinica1.webp';
import i2 from '../assets/images/BgReception.webp';
import i3 from '../assets/images/Clinica2.webp';
import { useTranslation } from "react-i18next"

function Slider2() {
    let sliderArr = [i1, i2, i3];
    let [x, setX] = useState(0)

    const goleft = () => {
        x === 0 ? setX(sliderArr.length - 1) : setX(x - 1);
    }
    const goright = () => {
        x === sliderArr.length - 1 ? setX(0) : setX(x + 1);
    }
    const dot = (i) => setX(i)
    const [t] = useTranslation("global");

    return (
        <div className="slider">
            <div className="slide" style={{ backgroundImage: `url(${sliderArr[x]})` }}>
                <button id="goleft" onClick={goleft}><i className="fas fa-chevron-left" /></button>
                <button id="goright" onClick={goright}><i className="fas fa-chevron-right" /></button>

                <div className="dots">
                    {
                        sliderArr.map((item, index) => {
                            return (
                                <span key={index} className="dot" onClick={() => dot(index)} style={{ background: `${x === index ? 'black' : 'gray'}` }} />
                            )
                        })}
                </div>
            </div>
            <NavLink className="pedircita" to="/formulario" activeclassname="active">
                    <img src={logo} className="logo-img" alt="logotipo" />
                    {t("slider.btnCita")}
                </NavLink>

        </div>
    );
}

export default Slider2;