import React, { Component } from 'react';
import Trabajador from './Trabajador';

import { withTranslation } from 'react-i18next';

import i1 from '../assets/images/Recepcion.webp';
import i2 from '../assets/images/Surgery.webp';
import i3 from '../assets/images/Toys.webp';
import i4 from '../assets/images/Hospital.webp';
import i5 from '../assets/images/Payment.webp';

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            servicios: [
                {
                    nombre: props.t("servicios.consultasTitle"), image: i1,
                    descripcion: props.t("servicios.consultas", { returnObjects: true })
                },
                {
                    nombre: props.t("servicios.cirugiaTitle"), image: i2,
                    descripcion: props.t("servicios.cirugia", { returnObjects: true })
                },
                {
                    nombre: props.t("servicios.hospitalizacionTitle"), image: i4,
                    descripcion: props.t("servicios.hospitalizacion")
                },
                {
                    nombre: props.t("servicios.tiendaTitle"), image: i3,
                    descripcion: props.t("servicios.tienda", { returnObjects: true })
                },
                {
                    nombre: props.t("servicios.facilidadesDePagoTitle"), image: i5,
                    descripcion: props.t("servicios.facilidadesDePago")
                }
            ]
        };
    }

    componentDidMount() {
        document.title = "Servicios - Veterinaria Los Cuartos";
    }

    render() {
        return (
            <React.Fragment>
                <div className="headerSpace" />
                <div className="container">
                    {
                        this.state.servicios.map((trabajador, i) => (
                            <Trabajador
                                key={i}
                                trabajador={trabajador}
                                indice={i}
                            />
                        ))
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default withTranslation("global")(Services);
