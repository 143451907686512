import React, { useState, useEffect, useRef } from 'react';
import '../assets/css/Services.css';
import { useNavigate } from 'react-router-dom';

const ServicesCard = ({ servicio }) => {
    const { nombre, descripcion, longDesc, listDesc, image } = servicio;
    const [height, setHeight] = useState(0);
    const [hover, setHover] = useState(false);
    const [matches, setMatches] = useState(window.matchMedia("(hover)").matches);
    const navigate = useNavigate();
    const divElementRef = useRef(null);

    const toggleHover = () => {
        setHover(!hover);
    };

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        window.matchMedia("(hover)").addEventListener("change", handler);

        if (divElementRef.current) {
            setHeight(divElementRef.current.clientHeight);
        }

        return () => {
            window.matchMedia("(hover)").removeEventListener("change", handler);
        };
    }, []);

    let linkStyle = {};
    if (matches && !hover) {
        linkStyle = { transform: `translateY(${height * 0.22}%)` };
    } else {
        linkStyle = { transform: `translateY(0%)` };
    }

    return (
        <div itemScope itemType="http://schema.org/PostalAddress" className="service" 
             onMouseEnter={toggleHover} onMouseLeave={toggleHover} 
             style={{ backgroundImage: `url(${image})` }}>
            <div className="service-content" style={linkStyle} ref={divElementRef}>
                <h2 itemProp="name" className="service-title">{nombre}</h2>
                <p itemProp="description" className="service-body">{descripcion}</p>
                <button onClick={() => {
                    navigate(`/servicios/${nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").split(" ").join("")}`, {
                        state: {
                            nombre,
                            image,
                            descripcion: longDesc,
                            lista: listDesc
                        }
                    });
                }} className="learn-more">
                    Leer más
                </button>
            </div>
        </div>
    );
};

export default ServicesCard;

