import React, { Component } from 'react';
import { lazyImage } from "../util"

class Trabajador extends Component {
    componentDidMount() {
        lazyImage()
    }
    render() {
        const { nombre, descripcion, image } = this.props.trabajador;

        return (
            <div className="card">
                <img data-src={image} className="lazy-image" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" alt={nombre} />
                <h4>{nombre}</h4>
                {typeof descripcion === 'object' ?
                    <ul style={{ textAlign: 'start', listStyleType: 'circle' }}>
                        {
                            descripcion.map((lista, i) => {
                                return (
                                    <li key={i}>
                                        {lista}
                                    </li>
                                );
                            })
                        }
                    </ul>
                    :
                    <p>{descripcion}</p>
                }
            </div>
        );
    }
}

export default Trabajador;