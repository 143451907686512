import React, { Component } from 'react';
import '../assets/css/Blog.css';
import { Link } from 'react-router-dom';
import { lazyImage } from "../util"

class Article extends Component {
    componentDidMount(){
        lazyImage()
    }
    render() {
        const { id, nombre, descripcion, longDesc, image } = this.props.article;

        return (
            <article className="article clearfixblog">
                <img data-src={image} className="lazy-image" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" alt={nombre} />
                <div className="post-preview">
                    <h1 className="p-1r">
                    <Link to={{
                        pathname: `/blog/${id}`,
                        state: {
                            nombre: nombre,
                            image: image,
                            descripcion: longDesc
                        }}}>
                        {nombre}
                        </Link>
                        </h1>
                    <i className="far fa-user p-1r p-1" />
                    <span>Admin</span>
                    &nbsp;
                    <i className="far fa-calendar p-1r p-1" />
                    <span>Jun 24, 2021</span>
                    <p className="preview-text p-1r">{descripcion}</p>
                    <Link to={{
                        pathname: `/blog/${id.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`,
                        state: {
                            nombre: nombre,
                            image: image,
                            descripcion: longDesc
                        }
                    }} className="learn-more">
                        Leer más
                    </Link>
                </div>
            </article>
        );
    }
}

export default Article;