import React, { Component } from 'react';
import '../assets/css/Blog.css';
import { lazyImage } from "../util"

class SingleArticle extends Component {
    componentDidMount(){
        lazyImage()
    }
    render() {
        const state = this.props.location && this.props.location.state ? this.props.location.state : {};
        const { nombre = '', descripcion = '', image = '' } = state;

        return (
            <React.Fragment>
                <div className="headerSpace" />
                <div className="blogContent clearfixblog">
                    <div className="single-content">
                        <article className="single">
                            <img data-src={image} className="lazy-image" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" alt={nombre} />
                            <div className="post-preview">
                                <h1 className="p-1r">{nombre}</h1>
                                <i className="far fa-user p-1r p-1" />
                                <span>Admin</span>
                                &nbsp;
                                <i className="far fa-calendar p-1r p-1" />
                                <span>Jun 24, 2021</span>
                                <p className="preview-text p-1r">{descripcion}</p>
                            </div>
                        </article>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default SingleArticle;