import React, { Component } from 'react';
import Sidebar from './Sidebar';
import '../assets/css/Blog.css';
import Article from './Article';

import { withTranslation } from 'react-i18next';

import i1 from '../assets/images/Recepcion.webp';
import i2 from '../assets/images/covid.webp';

class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articles: [
                {
                    id: "saludComp",
                    nombre: this.props.t('blog.compVet'), 
                    image: i1,
                    descripcion: this.props.t('blog.compShort'),
                    longDesc: this.props.t('blog.compLong')
                },
                {
                    id: "saludCOVID",
                    nombre: this.props.t('blog.covid19'), 
                    image: i2,
                    descripcion: this.props.t('blog.covid19Short'),
                    longDesc: this.props.t('blog.covid19Long')
                }
            ]
        };
    }

    componentDidMount() {
        document.title = "Blog - Veterinaria Los Cuartos";
    }

    render() {
        return (
            <React.Fragment>
                <div className="headerSpace" />
                <div className="blogContent clearfixblog">
                    <div className="main-content">
                        {
                            this.state.articles.map((article, i) => (
                                <Article
                                    key={i}
                                    article={article}
                                    indice={i}
                                />
                            ))
                        }
                    </div>
                    <Sidebar />
                </div>
            </React.Fragment>
        );
    }
}

export default withTranslation("global")(Blog);
