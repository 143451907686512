import React, { Component } from 'react';

class AvisoLegal extends Component {
    componentDidMount() {
        document.title = "Aviso Legal - Veterinaria Los Cuartos"
    }
    render() {
        return (
            <React.Fragment>
                <div className="headerSpace" />
                <div className="container">
                    <div className="center avisoLegal" style={{ textAlign: 'left', width: '100%' }}>
                        <table>
                            <tbody>
                                <tr>
                                    <td><strong>AVISO LEGAL</strong></td>
                                </tr>
                                <tr>
                                    <td>El presente aviso legal regula el uso del sitio web:<p></p>
                                        <p><a href="http://veterinarialoscuartos.com/"><span style={{ textDecoration: 'underline' }}>veterinarialoscuartos.com</span></a></p>
                                        <p>(en adelante, LA WEB), del que es titular la<em> Clínica Veterinaria Los Cuartos Sociedad Civil,</em>&nbsp;en adelante, EL PROPIETARIO DE LA WEB).</p>
                                        <p>La navegación por el sitio web de EL PROPIETARIO DE LA WEB atribuye la condición de usuario del mismo e implica la aceptación plena y sin reservas de todas y cada una de las disposiciones incluidas en este Aviso Legal, que pueden sufrir modificaciones.</p>
                                        <p>El usuario se obliga a hacer un uso correcto del sitio web de conformidad con las leyes, la buena fe, el orden público, los usos del tráfico y el presente Aviso Legal. El usuario responderá frente a EL PROPIETARIO DE LA WEB o frente a terceros, de cualesquiera daños y perjuicios que pudieran causarse como consecuencia del incumplimiento de dicha obligación.</p></td>
                                </tr>
                            </tbody>
                        </table>
                        <p>&nbsp;</p>
                        <table>
                            <tbody>
                                <tr>
                                    <td ><strong>IDENTIFICACIÓN Y COMUNICACIONES</strong></td>
                                </tr>
                                <tr>
                                    <td >Para dar cumplimiento de la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico, le informa de que:<p></p>
                                        <p><strong>Nombre de la compañía: </strong><em> Clínica Veterinaria Los Cuartos Sociedad Civil – J38992855</em></p>
                                        <p><strong> Dirección: Avenida Sor Soledad Cobián, Nº29. 38300, La Orotava, Santa Cruz de Tenerife. España</strong></p>
                                        <p><strong>Teléfono: 0034- </strong>922 32 22 94</p></td>
                                </tr>
                            </tbody>
                        </table>
                        <p>&nbsp;</p>
                        <table>
                            <tbody>
                                <tr>
                                    <td><strong>POLÍTICA DE PRIVACIDAD</strong></td>
                                </tr>
                                <tr>
                                    <td>Cuando precisemos obtener información por su parte, siempre le solicitaremos que nos la proporcione voluntariamente de forma expresa. Los datos recabados a través de los formularios de recogida de datos del sitio web u otras vías serán incorporados a un fichero de datos de carácter personal debidamente inscrito en el Registro General de Protección de Datos de la Agencia Española de Protección de Datos, del cual es <em>responsable Clínica Veterinaria Los Cuartos Sociedad Civil</em>.- Esta entidad tratará los datos de forma confidencial y exclusivamente con la finalidad de ofrecer los servicios solicitados, con todas las garantías legales y de seguridad que impone la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal, el Real Decreto 1720/2007, de 21 de diciembre y la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico.<p></p>
                                        <p><em> Clínica Veterinaria Los Cuartos Sociedad Civil</em>, se compromete a no ceder, vender, ni compartir los datos con terceros sin su aprobación expresa. Asimismo, la <em> Clínica Veterinaria Los Cuartos Sociedad Civil</em>, cancelará o rectificará los datos cuando resulten inexactos, incompletos o hayan dejado de ser necesarios o pertinentes para su finalidad, de conformidad con lo previsto en la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal.</p>
                                        <p>El usuario podrá revocar el consentimiento prestado y ejercer los derechos de acceso, rectificación, cancelación y oposición dirigiéndose a tal efecto al domicilio social de la<em> Clínica Veterinaria Los Cuartos Sociedad Civil</em>, sito en <em>la Avenida Sor Soledad Cobian, Nº29, 38300, La Orotava, Santa Cruz de Tenerife.</em> Identificándose debidamente e indicando de forma visible el concreto derecho que se ejerce. Nuestra política con respecto al correo electrónico se centra en remitir únicamente comunicaciones que usted haya solicitado recibir. Si prefiere no recibir estos mensajes por correo electrónico le ofreceremos a través de los mismos la posibilidad de ejercer su derecho de cancelación y renuncia a la recepción de estos mensajes, en conformidad con lo dispuesto en el Título III, artículo 22 de la Ley 34/2002 de Servicios para la Sociedad de la Información y de Comercio Electrónico.</p></td>
                                </tr>
                            </tbody>
                        </table>
                        <p>&nbsp;</p>
                        <p><strong>LEY DE COOKIES</strong>:&nbsp;<a href="https://www.agpd.es/portalwebAGPD/canaldocumentacion/publicaciones/common/Guias/Guia_Cookies.pdf">https://www.agpd.es/portalwebAGPD/canaldocumentacion/publicaciones/common/Guias/Guia_Cookies.pdf</a></p>
                        <h4>LEY DE COOKIES</h4>
                        <p>En cumplimiento con la Ley 34/2002, de 11 de julio de Servicios de la Información y de Comercio Electrónico la presente página web le informa sobre la política de recogida y tratamiento de las cookies.</p>
                        <h4>¿Qué es una cookie?</h4>
                        <p>Una cookie es un fichero que se descarga en un ordenador mientras se navega en una determinada web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario.</p>
                        <h4>¿Qué tipos de cookies existen?</h4>
                        <p>Existen diferentes tipos de cookies en función de los distintos criterios:</p>
                        <p>En función de su titularidad:</p>
                        <p><strong>Cookies propias:</strong>&nbsp;Son las que pertenecen a la entidad que gestiona el dominio que presta el servicio solicitado.</p>
                        <p><strong>Cookies de terceros:</strong>&nbsp;Son aquellas cuya titularidad es de un tercero, distinto de la entidad que gestiona el dominio que presta el servicio solicitado por el usuario, y que será quién trate la información recabada o aquéllas que son tratadas por un tercero.</p>
                        <p>En función del tiempo que permanecen activas en el equipo terminal:</p>
                        <p><strong>Cookies de sesión:</strong>&nbsp;Son aquellas cookies que se generan para recoger y almacenar los datos de navegación mientras el usuario accede a una página web. Permanecen activas hasta que se abandona la página y se cierra el navegador, de formar que al reiniciar el navegador y volver a visitar la página web, ésta no reconoce al usuario.</p>
                        <p><strong>Cookies permanentes:</strong>&nbsp;Son aquellas cookies que se almacenan en el equipo terminal y pueden ser accedidas durante el período de tiempo definido por la entidad que gestiona la cookie. Estos archivos permanecerán en el equipo hasta que se eliminen de forma manual o el navegador las elimine de acuerdo con el periodo de duración establecido de la cookie, que puede ser de unos minutos a varios años.</p>
                        <p>En función de su finalidad:</p>
                        <p><strong>Cookies técnicas:</strong>&nbsp;Son aquéllas estrictamente necesarias para el correcto funcionamiento de una página web. Se generan cuando el usuario accede o inicia sesión en el sitio y se utilizan para identificarlo durante su navegación en el sitio y para comprobar si está autorizado a acceder a servicios o zonas restringidas del sitio web.</p>
                        <p><strong>Cookies de personalización:</strong>&nbsp;Son aquellas cookies que permiten al usuario personalizar algunas funciones o contenidos del sitio web en función de las características definidas en su terminal o por él mismo al acceder al sitio.</p>
                        <p><strong>Cookies de análisis:</strong>&nbsp;Son aquellas cookies que permiten al responsable de las mismas realizar el seguimiento y análisis del comportamiento del usuario cuando se conecta a un sitio web. Permiten cuantificar el número de usuarios y realizar análisis estadísticos sobre la utilización del sitio con el fin de mejorar los servicios ofrecidos.</p>
                        <p><strong>Cookies publicitarias:</strong>&nbsp;Son aquellas que permiten gestionar, de la forma más eficaz posible, la oferta de los espacios publicitarios que se presentan en un sitio web en base a criterios como pueden ser el contenido o la frecuencia con la que aparecen los anuncios.</p>
                        <p><strong>Cookies de publicidad comportamental:</strong>&nbsp;Son aquellas que permiten gestionar, de la forma más eficaz posible, la oferta de los espacios publicitarios que se presentan en un sitio web. Estas cookies almacenan información del comportamiento que tienen los usuarios extraída por la observación continuada de sus hábitos de navegación. De esta forma se desarrolla un perfil de navegación y se muestra publicidad en función del mismo.</p>
                        {/*<h4>¿Qué tipo de cookies utiliza esta página web?</h4>
                        <p><strong>Cookies propias:</strong></p>
                        <p>– qtrans_front_language – es una cookie perteneciente al plugin Qtranslate de WordPress y corresponde a la categoria “Cookie de personalización”. Detecta el idioma del browser del usuario. Tiene una duración de 365 días hasta caducar.</p>
                        <p>– wfvt_3536830302 – es una cookie perteneciente al plugin Wordfence de WordPress. Corresponde a la categoria “Cookie de analisis” y sirve para detectar acciones malignas de usuarios sobre el contenido de la web. Wordfence es un plugin de seguridad para WordPress. Tiene una duración de 30 minutos hasta caducar.</p>
                        <p><strong>Cookies de terceros:</strong></p>
                        <p>– _utma, _utmz, _ga, _gat, _gid – son cookies que pertenecen al servicio de Google Analytics. Corresponden a la categoria “Cookie de analisis” y sirven para generar estadisticas de trafico de la web. Caducan con el cierre de la pagina.</p>
                        <p>Los usuarios podrán rechazar el tratamiento de tales datos o información rechazando el uso de Cookies mediante la selección de la configuración apropiada a tal fin en su navegador.</p>
                        <p>Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador. En los siguientes enlaces obtendrá información de los navegadores más comúnes:</p>
                        */}<ul>
                            <li>Para más información del Navegador Chrome&nbsp;<a href="http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647">pulse aquí</a></li>
                            <li>Para más información del Navegador Explorer&nbsp;<a href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9">pulse aquí</a></li>
                            <li>Para más información del Navegador Firefox&nbsp;<a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">pulse aquí</a></li>
                            <li>Para más información del Navegador Safari&nbsp;<a href="http://support.apple.com/kb/ph5042">pulse aquí</a></li>
                        </ul>
                        {/*<p><strong>SOLICITAR LA BAJA</strong></p>
                    <p>Solicitar la baja en comunicaciones comerciales y otras: <a href="http://www.clinicaveterinarialoscuartos.net/baja/">BAJA</a></p>*/}
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AvisoLegal;