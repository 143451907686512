import React from 'react';
import { useLocation } from 'react-router-dom';
import '../assets/css/Blog.css';
import { lazyImage } from "../util";

const SingleService = () => {
    const { state } = useLocation();
    const { nombre = '', descripcion = '', lista = [], image = '' } = state || {};
    React.useEffect(() => {
        lazyImage();
    }, []);

    return (
        <React.Fragment>
            <div className="headerSpace" />
            <div className="blogContent clearfixblog">
                <div className="single-content">
                    <article itemScope itemType="http://schema.org/Product" className="single">
                        <img itemProp="image" data-src={image} className="lazy-image" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" alt={nombre} />
                        <div className="post-preview">
                            <h1 itemProp="name" className="p-1r">{nombre}</h1>
                            {typeof lista !== 'object' ?
                                typeof descripcion === 'object' ?
                                    <ul>
                                        {descripcion.map((item, i) => (
                                            <li key={i}>{item}</li>
                                        ))}
                                    </ul>
                                    :
                                    <p itemProp="description" className="service-body p-1r">{descripcion}</p>
                                :
                                <div>
                                    <p itemProp="description" className="service-body p-1r">{descripcion}</p>
                                    <ul>
                                        {lista.map((item, i) => (
                                            <li key={i}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            }
                        </div>
                    </article>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SingleService;
