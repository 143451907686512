import React, { Component } from 'react';

//Componente de Rutas
import { BrowserRouter, Route, Routes } from 'react-router-dom';

//Importar Componentes
import Header from './components/Header';
import Home from './components/Home';
import Blog from './components/Blog';
import SingleArticle from './components/SingleArticle';
import SingleService from './components/SingleService';
import Services from './components/Services';
import Formulario from './components/Formulario';
import Trabajadores from './components/Trabajadores';
import AvisoLegal from './components/AvisoLegal';
import Footer from './components/Footer';
import Cookies from './Cookies';

class Router extends Component {
    render() {
        return (
            <BrowserRouter>
                <Header />

                {/*Configurar rutas y paginas*/}
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/blog/:id" element={<SingleArticle />} />
                    <Route path="/servicios" element={<Services />} />
                    <Route path="/servicios/:id" element={<SingleService />} />
                    <Route path="/equipo" element={<Trabajadores />} />
                    <Route path="/formulario" element={<Formulario />} />
                    <Route path="/aviso-legal" element={<AvisoLegal />} />

                </Routes>

                <div className="clearfix"></div>
                <Footer/>
                <Cookies/>
            </BrowserRouter>
        );
    }
}

export default Router;